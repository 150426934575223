// TODO: Junaid - To review
import pick from 'lodash/pick';
import identity from 'lodash/identity';
import { createDate } from 'utils/date';
// eslint-disable-next-line import/no-cycle
import { getCityCountry } from 'utils/globals';
import { getEntityAndTypeFromContributor } from 'utils/productions';

import { PERFORMANCE_DATE_MODE_TYPES, ENTITY_TYPE, DATE_FORMATS } from 'constants/index';
import { SECTION_KEYS } from './constants';

const PERFORMANCE_KEYS_TO_PICK = [
  'city',
  'country',
  'isCancelled',
  'isMatinee',
  'isPremiere',
  'isTour',
  'isWorldPremiere',
  'mode',
  'startDate',
  'startTime',
  'venue',
  'stage',
];
const UPCOMING_PERFORMANCES_KEYS_TO_PICK = ['id', 'startDate', 'startTime', 'isCancelled', 'city', 'country', 'venue'];
const LIVESTREAM_VOD_KEYS_TO_PICK = [
  'id',
  'startDate',
  'startTime',
  'endDate',
  'isCancelled',
  'attributes',
  'timezone',
  'lsVodData',
];

// Example output = {
//   '2024': {
//     5: { // June (months are 0-indexed, so June is 5)
//       'Teatro alla Scala': [
//         {
//           date: '2024-06-15',
//           time: '20:00',
//           city: 'Milan',
//           country: 'Italy',
//           venue: 'Teatro alla Scala',
//           soldOut: true,
//         },
//         // More performances at Teatro alla Scala in June 2024
//       ],
//       // Other venues in June 2024
//     },
//     6: { // July
//       // Venues and performances in July 2024
//     },
//   },
//   // Other years
// };
export const groupPerformancesByDateAndVenue = production => {
  const performances = production?.performances?.filter(identity);

  if (!performances?.length) {
    return {};
  }

  performances.sort((a, b) => createDate(a.startDate).unix() - createDate(b.startDate).unix());

  const organizedData = {};
  performances.forEach(performance => {
    if (
      performance.mode === PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO ||
      performance.mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM ||
      typeof performance.startDate !== 'string'
    ) {
      return;
    }

    const date = new Date(performance.startDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const venueName = performance?.venue?.slug || performance?.venue?.id;
    const stageId = performance?.stage?.slug || performance?.stage?.id;
    const venueGroupingKey = stageId ? `${venueName}_${stageId}` : venueName;

    if (!organizedData[year]) {
      organizedData[year] = {};
    }
    if (!organizedData[year][month]) {
      organizedData[year][month] = {};
    }
    if (!organizedData[year][month][venueGroupingKey]) {
      organizedData[year][month][venueGroupingKey] = [];
    }

    organizedData[year][month][venueGroupingKey].push({
      ...pick(performance, PERFORMANCE_KEYS_TO_PICK),
    });
  });

  return organizedData;
};

export const isPastPerformance = performance => {
  const today = createDate().startOf('day');
  const futureDate = performance.startTime
    ? createDate(`${performance.startDate} ${performance.startTime}`, 'YYYY-MM-DD HH:mm')
    : createDate(performance.startDate, 'YYYY-MM-DD').endOf('day');

  return today.isAfter(futureDate);
};

export const groupPerformances = production => {
  const performances = production?.performances || [];

  // Sort performances by date
  performances.sort((a, b) => createDate(a?.startDate).unix() - createDate(b?.startDate).unix());

  return performances.reduce(
    (performanceGrouping, performance) => {
      const isValidPerf = typeof performance?.startDate === 'string';
      if (!performance || !isValidPerf) return performanceGrouping;

      const isLivestream = performance.mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM;
      const isFullVideo = performance.mode === PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO;
      const isUpcomingPerformance = !isPastPerformance(performance);

      if (isUpcomingPerformance && !isLivestream && !isFullVideo) {
        performanceGrouping.UPCOMING.push({
          ...pick(performance, UPCOMING_PERFORMANCES_KEYS_TO_PICK),
          location: getCityCountry(performance.venue),
        });
      }

      if (isLivestream) {
        performanceGrouping.LIVESTREAM.push({ ...pick(performance, LIVESTREAM_VOD_KEYS_TO_PICK) });
      }

      if (isFullVideo) {
        performanceGrouping.FULL_VIDEO.push({ ...pick(performance, LIVESTREAM_VOD_KEYS_TO_PICK) });
      }

      return performanceGrouping;
    },
    { UPCOMING: [], LIVESTREAM: [], FULL_VIDEO: [] },
  );
};

export const hasPastPerformances = production =>
  production?.performances?.some(
    performance =>
      performance &&
      isPastPerformance(performance) &&
      performance.mode !== PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO &&
      performance.mode !== PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM &&
      typeof performance.startDate === 'string',
  );

function addUniqueProfessional(professionSlug, uniqueProfessionals, creator) {
  if (!professionSlug) return;

  if (!uniqueProfessionals[professionSlug]) {
    // eslint-disable-next-line no-param-reassign
    uniqueProfessionals[professionSlug] = {
      data: [],
      title: creator.profession.name,
      slug: creator.profession.slug,
    };
  }
  const professionalType = uniqueProfessionals[professionSlug];
  const exists = professionalType.data.some(entry => entry.entity?.id === creator.profile?.id);
  if (!exists) {
    const { entity, entityType } = getEntityAndTypeFromContributor(creator);
    professionalType.data.push({
      entity,
      entityType,
      isLinkable: true,
    });
  }
}

// Returns an object with unique professionals grouped by profession
// { composer, librettist, writer, ... }
export const getUniqueProfessionalsFromCreators = production => {
  const productionWorks = production?.productionWorks || [];
  const uniqueProfessionals = {};

  productionWorks.forEach(work => {
    const creators = work?.work?.creators || [];
    creators.forEach(creator => {
      const professionSlug = creator?.profession?.slug;
      addUniqueProfessional(professionSlug, uniqueProfessionals, creator);
    });
  });

  return uniqueProfessionals;
};

export const getPerformanceLinkProps = ({ performance, production, navigate }) => {
  const dateObj = createDate(performance.startDate, DATE_FORMATS.FULL_DATE);
  return {
    linkProps: navigate.getLinkProps({
      entityType: ENTITY_TYPE.PRODUCTION,
      entity: production,
      path: dateObj.locale('en').format(DATE_FORMATS.URL_FULL_DATE),
    }),
    dateObj,
  };
};

// credits: getProductionCredits(production);
export const getSectionsVisibility = (credits, production) => ({
  [SECTION_KEYS.CAST]: credits.cast?.length > 0,
  [SECTION_KEYS.CREW]:
    credits.directors?.data?.length > 0 || credits.conductors?.data?.length > 0 || credits.crew?.length > 0,
  [SECTION_KEYS.INSTRUMENTALISTS]: credits.instrumentalists?.length > 0,
  [SECTION_KEYS.ENSEMBLES]: credits.ensembles?.length > 0,
  [SECTION_KEYS.CO_PRODUCERS]: credits.coProducers?.data?.length > 0,
  [SECTION_KEYS.REVIEWS]: production?.stats?.reviews?.exists,
  [SECTION_KEYS.VIDEOS]: production?.stats?.media?.videos?.exists,
  [SECTION_KEYS.PHOTOS]: production?.stats?.media?.images?.exists,
  [SECTION_KEYS.ABOUT]: production?.stats?.about?.exists,
  [SECTION_KEYS.DATES_VENUES]: true,
});

// const sampleInput = [
//   {
//     title: 'Conductor',
//     hasCurrentEntity: false,
//     data: [
//       {
//         entity: {
//           id: 1,
//           name: 'John Doe',
//           slug: 'john-doe',
//         },
//         entityType: 'artist',
//         contributionId: 101,
//         performances: ['2024-01-01', '2024-01-02'],
//         productionWorks: [
//           { id: 1001, name: 'Symphony No. 5' },
//           { id: 1002, name: 'Symphony No. 9' },
//         ],
//         isCover: false,
//       },
//       {
//         entity: {
//           id: 2,
//           name: 'Jane Smith',
//           slug: 'jane-smith',
//         },
//         entityType: 'artist',
//         contributionId: 102,
//         performances: ['2024-01-01'],
//         productionWorks: [
//           { id: 1001, name: 'Symphony No. 5' },
//           { id: 1002, name: 'Symphony No. 9' },
//         ],
//         isCover: false,
//       },
//     ],
//     showDates: true,
//   },
//   {
//     title: 'Soloist',
//     hasCurrentEntity: false,
//     data: [
//       {
//         entity: {
//           id: 3,
//           name: 'Alice Johnson',
//           slug: 'alice-johnson',
//         },
//         entityType: 'artist',
//         contributionId: 103,
//         performances: ['2024-01-03'],
//         productionWorks: [{ id: 1003, name: 'Piano Concerto No. 21' }],
//         isCover: false,
//       },
//       {
//         entity: {
//           id: 4,
//           name: 'Bob Brown',
//           slug: 'bob-brown',
//         },
//         entityType: 'artist',
//         contributionId: 104,
//         performances: ['2024-01-04'],
//         productionWorks: [{ id: 1004, name: 'Violin Concerto in D major' }],
//         isCover: false,
//       },
//     ],
//     showDates: true,
//   },
// ];

// const sampleOutput = [
//   {
//     title: 'Conductor',
//     hasCurrentEntity: false,
//     data: [
//       {
//         entity: { id: 1, name: 'John Doe', slug: 'john-doe' },
//         entityType: 'artist',
//         contributionId: 101,
//         performances: ['2024-01-01', '2024-01-02'],
//         productionWorks: [
//           { id: 1001, name: 'Symphony No. 5' },
//           { id: 1002, name: 'Symphony No. 9' },
//         ],
//         isCover: false,
//       },
//       {
//         entity: { id: 2, name: 'Jane Smith', slug: 'jane-smith' },
//         entityType: 'artist',
//         contributionId: 102,
//         performances: ['2024-01-01'],
//         productionWorks: [
//           { id: 1001, name: 'Symphony No. 5' },
//           { id: 1002, name: 'Symphony No. 9' },
//         ],
//         isCover: false,
//       },
//     ],
//     showDates: true,
//   },
//   {
//     title: 'Soloist',
//     hasCurrentEntity: false,
//     data: [
//       {
//         entity: { id: 3, name: 'Alice Johnson', slug: 'alice-johnson' },
//         entityType: 'artist',
//         contributionId: 103,
//         performances: ['2024-01-03'],
//         productionWorks: [{ id: 1003, name: 'Piano Concerto No. 21' }],
//         isCover: false,
//       },
//     ],
//     showDates: true,
//   },
//   {
//     title: 'Soloist',
//     hasCurrentEntity: false,
//     data: [
//       {
//         entity: { id: 4, name: 'Bob Brown', slug: 'bob-brown' },
//         entityType: 'artist',
//         contributionId: 104,
//         performances: ['2024-01-04'],
//         productionWorks: [{ id: 1004, name: 'Violin Concerto in D major' }],
//         isCover: false,
//       },
//     ],
//     showDates: true,
//   },
// ];
export const splitOrMergeContributions = items =>
  items?.reduce((accum, contributorItem) => {
    const { data: contributors } = contributorItem || {};
    if (!contributors || contributors.length === 0) {
      return accum;
    }

    if (contributors.length === 1) {
      return [...accum, contributorItem];
    }

    // Extract ids from the first contributor's productionWorks and create a set
    const firstContributorWorkIds = new Set(contributors[0].productionWorks.map(work => work.id));

    const isSameWorksForAll = contributors.every(contributor => {
      // Create a set from current contributor's work ids
      const currentContributorWorkIds = new Set(contributor.productionWorks.map(work => work.id));
      if (firstContributorWorkIds.size !== currentContributorWorkIds.size) {
        return false;
      }
      // Check if every element in the first set is present in the current set
      return [...firstContributorWorkIds].every(id => currentContributorWorkIds.has(id));
    });

    if (isSameWorksForAll) {
      return [...accum, contributorItem];
    }

    // If works are different, split each contributor into separate items
    const splitItems = contributors.map(contributor => ({
      ...contributorItem,
      data: [contributor],
    }));
    return [...accum, ...splitItems];
  }, []);
